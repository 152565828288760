import {ReplaySubject} from 'rxjs';
import { userService } from './user.service';
import axios from 'axios';
import _ from 'lodash';

class BountiesService {
    bountiesSubject = new ReplaySubject<any>(1);
    activeBountiesSubject = new ReplaySubject<any>(1);
    bounties: any = [];
    activeBounties: any = [];
    allBounties = [];

    constructor() {
        this.bountiesSubject.next(this.bounties);
        this.fetchBounties();
    }

    async fetchBounties() {
        await userService.ready;
        if (!userService.jwt) {
            return;
        }
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.get('/api/bounties/',  {headers: authHeaders});
        this.bounties = resp.data;
        this.bountiesSubject.next(this.bounties);

        await userService.ready;
        const activeResp = await axios.get('/api/bounties/active', {headers: authHeaders});
        this.activeBounties = activeResp.data;
        this.activeBountiesSubject.next(activeResp.data);   
    }

    async reserve(bounty: any, reason: string) {
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        await axios.post('/api/bounties/reserve', {bounty, reason}, {headers: authHeaders});
        await this.fetchBounties();
    }

    async cancel(bounty: any) {
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        await axios.post('/api/bounties/cancel', bounty, {headers: authHeaders});
        await this.fetchBounties();
    }

    async approve(action: any) {
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.post('/api/bounties/approve', {action}, {headers: authHeaders});
        return resp.data;
    }

    async approveSample(action: any) {
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.post('/api/bounties/approveSample', {action}, {headers: authHeaders});
        return resp.data;
    }

    async approveVideo(action: any) {
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.post('/api/bounties/approveVideo', {action}, {headers: authHeaders});
        return resp.data;
    }

    async getBounty(id: string) {
       if (!this.allBounties.length) {
            const resp = await axios.get('/api/bounties/all');
            this.allBounties = resp.data;
       } 
       //TODO we should fetch a single one probably
       return _.find(this.allBounties, (b: any) => b.name === id);
    }

    async submitSample(action: any, data: any) {
        console.log('submit sample');

        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        await axios.post('/api/bounties/submitSample', {action, data}, {headers: authHeaders});
    }

    async reject(action: any,) {

        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.post('/api/bounties/reject', {action}, {headers: authHeaders});
        return resp;
    }

    async rejectSample(action: any,) {

        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.post('/api/bounties/rejectSample', {action}, {headers: authHeaders});
        return resp;
    }

    async rejectVideo(action: any,) {

        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.post('/api/bounties/rejectVideo', {action}, {headers: authHeaders});
        return resp;
    }

    async submitVideo(action: any, data: any) {
        console.log('submit video');
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
       await axios.post('/api/bounties/submitVideo', {action, data}, {headers: authHeaders});
    }

    async markPaid(action: any) {
        console.log('submit video');
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        await axios.post('/api/bounties/markPaid', {action}, {headers: authHeaders});
    }

    getBounties() {
        return this.bountiesSubject.asObservable();
    }

    getActiveBounties() {
        return this.activeBountiesSubject.asObservable();
    }

    async getGameActions(id: string) {
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.get(`/api/bounties/${id}/actions`, {headers: authHeaders});
        return JSON.parse(JSON.stringify(resp.data));
    }

    async getClaimedBounties() {
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.get('/api/bounties/claimed', {headers: authHeaders});
        return resp.data;
        
    }
}

export const bountiesService = new BountiesService();