<template>
    <span :class="'animated-' + id" class="animated-number">{{ number.number }}</span>
</template>
<script setup>
import { ref, watch, defineProps } from 'vue';
import { Back, TimelineLite} from 'gsap';

const number = defineProps({number: Number});
let prevNumber = 0;
let id = ref(Math.floor(Math.random()*1000000000));

watch(number, (newNumber) => {
  if(prevNumber < newNumber.number) {
      //green
      const tl = new TimelineLite();
      
      tl.to(`.animated-${id.value}`, 0, {css:{color:"#00FF00"}, ease:Back.easeIn, duration: 3});
      tl.to(`.animated-${id.value}`, 2, {css:{color:"#FAFAFA"}, ease:Back.easeOut, delay: 1, duration: 3});
      tl.play();
  } else if(prevNumber > newNumber.number) {
      //red
    const tl = new TimelineLite();
    tl.to(`.animated-${id.value}`, 0, {css:{color:"#FF0000"}, ease:Back.easeIn, duration: 3});
    tl.to(`.animated-${id.value}`, 2, {css:{color:"#FAFAFA"}, ease:Back.easeOut, delay: 1, duration: 3});
    tl.play();  
  }
  prevNumber = newNumber.number;
});
</script>

<style scoped>
.animated-number {
    color: #FAFAFA;
}
</style>
  