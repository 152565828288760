import {ReplaySubject} from 'rxjs';
import axios from 'axios';
import { useRouter } from "vue-router";
import {socketService} from './socket.service';

class UserService {
    routesSubject = new ReplaySubject<any[]>(1);
    currentUserSubject = new ReplaySubject<any>(1);
    routes = [{name: 'news', route: ''}];
    jwt = '';
    username = '';
    profileImageLink = '';
    admin = false;
    ready:any = null;
    resolve: any;
    constructor() {
        this.ready = new Promise((resolve) => this.resolve = resolve);
        this.currentUserSubject.next(null);
        try {
            const jwt = localStorage.getItem('jwt');
            if (jwt) {
                this.jwt = jwt;
                this.ready = this.doUserSetup();
            } else {
                this.routes = [{name: 'login', route: 'login'}];
                this.routesSubject.next(this.routes);
                if (this.resolve) {
                    this.resolve();
                    this.resolve = null;
                }
            }  
        } catch(e) {
            this.jwt = '';
            this.routes = [{name: 'news', route: ''}, {name: 'login', route: 'login'}];
            this.routesSubject.next(this.routes);
            if (this.resolve) {
                this.resolve();
                this.resolve = null;
            }
            console.log('no jwt found, please log in');
        }
    }

    async doUserSetup() {
        const authHeaders = { 'Authorization': 'Bearer ' + this.jwt}
        const meResp = await axios.get(`/authed-api/me`, {headers: authHeaders});

        this.username = meResp.data.login;
        this.profileImageLink = meResp.data.profileImageLink;
        this.admin = !!meResp.data.admin;

        this.currentUserSubject.next({
            username: this.username,
            profileImageLink: this.profileImageLink,
        });

        this.routes = [
            {name: 'news', route: ""},
            {name: 'bounties', route: 'bounties'},
            {name: 'giveaways', route: 'giveaways'},
        ];
        if(this.admin) {
            this.routes.push({name: 'admin', route: 'admin'});
        }
        this.routesSubject.next(this.routes);
        await socketService.ready;

        socketService.on('authenticated', () => {
            console.log('authenticated');
        });
        socketService.emit('authenticate', {token: this.jwt});
        if (this.resolve) {
            this.resolve();
            this.resolve = null;
        }
    }

    getUserRoutes() {
        return this.routesSubject.asObservable();
    }

    getCurrentUser() {
        return this.currentUserSubject.asObservable();
    }

    isLoggedIn() {
        return !!this.jwt;
    }

    async login(token: string) {
        try {
            const resp = await axios.post(`/api/auth/login?token=${token}`);
            const jwt = resp.data.token;
            localStorage.setItem('jwt', jwt);
            this.jwt = jwt;
            await this.doUserSetup()

        } catch(e) {
            const router = useRouter();
            router.push('/login');
        }
        
    }

    async logout() {
        this.jwt = '';
        this.admin = false;
        this.username = '';
        this.profileImageLink = '';
        localStorage.removeItem('jwt');
        this.routes = [{name: 'news', route: ''}, {name: 'login', route: 'login'}];
        this.currentUserSubject.next(null);
        this.routesSubject.next(this.routes);

    }

    async setUserData(user: any) {
        const authHeaders = { 'Authorization': 'Bearer ' + this.jwt}
        const resp = await axios.put(`/api-authed/user/`, user, {headers: authHeaders});
        return resp.data;
    }

    async getUserData()  {
        const authHeaders = { 'Authorization': 'Bearer ' + this.jwt}
        const resp = await axios.get(`/api-authed/user/`, {headers: authHeaders});
        return resp.data;
    }

    async joinProgram() {
        const authHeaders = { 'Authorization': 'Bearer ' + this.jwt}
        const resp = await axios.post(`/api-authed/user/bounty/join`, {}, {headers: authHeaders});
        return resp.data;
    }

    async completeTask(task: any) {
        const authHeaders = { 'Authorization': 'Bearer ' + this.jwt}
        const resp = await axios.post(`/api-authed/user/bounty/task`, task, {headers: authHeaders});
        return resp.data;
    }

    async promoteBountyLevel() {
        const authHeaders = { 'Authorization': 'Bearer ' + this.jwt}
        const resp = await axios.post(`/api-authed/user/bounty/promote`, {}, {headers: authHeaders});
        return resp.data;
    }
}

export const userService = new UserService();