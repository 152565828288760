import {ReplaySubject} from 'rxjs';

import axios from 'axios';

class HowLongService {
    giveawaySubject = new ReplaySubject<any>(1);

    async getGameDetails(game: string) {
        //do we need to do anything here to make sure we have jwt?
        const resp = await axios.get(`/api/howlong/${game}`);
        return resp.data
    }


}

export const howLongService = new HowLongService();