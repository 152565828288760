import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14cea6b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_v_timeline = _resolveComponent("v-timeline")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { style: {"margin":"15px","padding":"8px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createTextVNode("Past Giveaways")
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider),
        _createVNode(_component_v_timeline, {
          density: "compact",
          size: "xx-small"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.giveaways, (giveaway) => {
              return (_openBlock(), _createBlock(_component_v_timeline_item, { key: giveaway }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(giveaway.date), 1),
                    _createElementVNode("div", null, _toDisplayString(giveaway.title) + " code " + _toDisplayString(giveaway.code) + " on " + _toDisplayString(giveaway.platform), 1)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}