import { ReplaySubject } from "rxjs";
import { userService } from "./user.service";
import {socketService } from '../services/socket.service';
import _ from 'lodash';
import axios from 'axios';

class NotificationService {
    notificitonSubject = new ReplaySubject<any>(1);
    notifications: any[] = [];
    constructor() {
        this.notificitonSubject.next(this.notifications);
        userService.getCurrentUser().subscribe((data) => {
            if (data) {
                //fetch notifications for user
                this.fetchNotifications();
            }

        });


        socketService.on('notification:new', (notification: any) => {
            this.notifications.push(notification);
            this.notificitonSubject.next(this.notifications);
        });

        socketService.on('notification:remove', (notification: any) => {
            this.notifications = _.filter(this.notifications, (n) => n.id !== notification.id);
            this.notificitonSubject.next(this.notifications);
        });
    }

    getNotifications() {
        return this.notificitonSubject.asObservable();
    }

    async fetchNotifications() {
        await userService.ready;
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt}
        const resp = await axios.get(`/api/notifications`, {headers: authHeaders});
        this.notifications = resp.data;
        this.notificitonSubject.next(this.notifications);
    }
}

export const notificationService = new NotificationService();