class SocketService {
    socket: any = null;
    ready: any;
    resolve: any;
    constructor() {
        this.ready = new Promise((resolve) => this.resolve = resolve);
        const scriptEle = document.createElement('script');
        
        scriptEle.setAttribute('src', '/socket.io/socket.io.js');
        document.body.appendChild(scriptEle);
        scriptEle.addEventListener("load", () => {
            this.socket = (window as any).io();
            this.socket.on('connected', () => {
                console.log('socket connected');
            });
            this.resolve();
        });
    }
    
    async emit(event: string, params?: any, cb?: any) {
        await this.ready;
        this.socket.emit(event, params, cb);
    }
    
    async on(event: string, fn: any) {
        await this.ready;
        this.socket.on(event, (data: any) => {
            fn(data);
        });
      }
}

export const socketService = new SocketService();