import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-288bd662"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_Markdown = _resolveComponent("Markdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("img", {
        class: "centered",
        src: _ctx.article.headlineImage
      }, null, 8, _hoisted_1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.article.title), 1)
    ]),
    _createVNode(_component_v_divider),
    _createVNode(_component_Markdown, {
      style: {"margin-top":"3px"},
      class: "faded",
      source: _ctx.article.markdownContent
    }, null, 8, ["source"])
  ]))
}