
import { Component, Vue} from 'vue-facing-decorator';
import { youtubeService } from '../services/youtube.service';

@Component({
    components: {}
})
export default class YoutubeSummary extends Vue {
    videos = [];
    youtubeSub: any = null;
    colors = [ 'red-lighten-2', 'purple-lighten-2', 'green-lighten-1', 'indigo-lighten-2'];
    async mounted() {
        this.youtubeSub = youtubeService.getYoutube().subscribe((videos) => {
            this.videos = videos;
        });
    }

    unmounted() {
        this.youtubeSub?.unsubscribe();
        this.youtubeSub = null;
    }
}

