<template>
    <div style="width: 100%; overflow-y: auto;">
        <v-expansion-panels style="width: 100%;">
            <v-expansion-panel
            >
                <v-expansion-panel-title>Overall Summary</v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div><span class="label">channels </span><span>{{this.channels?.length}}</span></div>
                    <div><span class="label">online </span><span>{{this.totalOnline}}</span></div>
                    <div><span class="label">viewers </span><span>{{this.totalViewers}}</span></div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
         <v-container fluid>
            <v-row dense>
                <Channel :channel="channel" v-for="channel in channels" v-bind:key="channel.name"></Channel>
            </v-row>
         </v-container>
    </div>
</template>
<script>
import { Component, Vue } from 'vue-facing-decorator';
import { channelsService } from '../services/channels.service';
import Channel from '../components/Channel';
import _ from 'lodash';

@Component({
    components: {
        Channel,
    }
})
export default class extends Vue {
    channels = [];
    totalOnline = 0;
    totalViewers = 0;
    mounted() {
        channelsService.getChannelSummary().subscribe((summary) => {
            this.totalOnline = _.filter(summary, (data) => data.streamOnline).length;
            this.totalViewers = _.sumBy(summary, 'viewers') + 1;
        });
        channelsService.getChannels().subscribe((channels) => {
            this.channels = channels;
        });
    }
}

</script>

<style scoped>
.label {
    opacity: .5;
    display: inline-block;
    width: 120px;
}
</style>