import {ReplaySubject} from 'rxjs';
import {userService} from './user.service';

import axios from 'axios';

class GiveawayManager {
    giveawaySubject = new ReplaySubject<any>(1);

    async getGiveaways() {
        //do we need to do anything here to make sure we have jwt?
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt}
        const resp = await axios.get(`/api-authed/giveaway/`, {headers: authHeaders});
        console.log(resp.data);
        return resp.data
    }


}

export const giveawayManager = new GiveawayManager();