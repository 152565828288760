
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import ChannelSummary from '@/components/ChannelsSummary.vue';
import ArticleSummary from '@/components/ArticleSummary.vue';
import {articlesService} from '../services/articles.service';
import BountySummary from '@/components/BountySummary.vue';
import YoutubeSummary from '@/components/YoutubeSummary.vue';

@Options({
  components: {
    HelloWorld,
    ChannelSummary,
    ArticleSummary,
    BountySummary,
    YoutubeSummary,
  },
})
export default class HomeView extends Vue {
  articles = [];
  articlesSub: any = null;

  icons = [
    'mdi-facebook',
    'mdi-twitter',
    'mdi-linkedin',
    'mdi-instagram',
  ];

  mounted() {
    this.articlesSub = articlesService.getArticles().subscribe((articles) => {
      this.articles = articles;
    });
  }

  unmounted() {
    this.articlesSub?.unsubscribe();
  }

  goToArticle(article: any) {
    this.$router.push({path: '/article/' + article.id});
  }
}
