
import { Component, Vue, Prop } from 'vue-facing-decorator';
import Markdown from 'vue3-markdown-it';

@Component({
    components: {
        Markdown,
    }
})
export default class ArticleSummary extends Vue {
   @Prop article?: any;

}

