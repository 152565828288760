import { socketService } from '../services/socket.service';
import {ReplaySubject} from 'rxjs';
import axios from 'axios';

class BotsService {
    bots: any = {
        'twitch_backgroundgaming_': {},
        'twitch_backgroundgaming_retro': {},
        'twitch_backgroundgaming_rpg': {},
        'twitch_zelda_tv247': {},
        'twitch_finalfantasy_tv247': {},
        'twitch_mario_tv247': {},
        'twitch_pokemon_tv247': {},
        'twitch_backgroundgaming_2': {},
    };
    botsSubject = new ReplaySubject<any>(1);
    constructor() {
        this.botsSubject.next(this.bots);
        this.setupData();
    }

    async setupData() {
        const resp = await axios.get(`/api/bot/status`);
        this.bots = resp.data;
        this.botsSubject.next(this.bots);


        socketService.on('bot:update', (data: any) => {
            //update the bot and send event
            const key = data.platform + '_' + data.channel;
            this.bots[key] = data;
            this.botsSubject.next(this.bots);
        });
    }

    getBots() {
        return this.botsSubject.asObservable();
    }
}

export const botsService = new BotsService();