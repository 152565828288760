
import { Component, Vue } from 'vue-facing-decorator';
import {notificationService} from '../services/notifications.service';

@Component({
    components: {}
})
export default class Notification extends Vue {
    notificationsSub: any = null;
    notifications: any[] = [{title: 'No new notifications'}];
    colors = [ 'red-lighten-2', 'purple-lighten-2', 'green-lighten-1', 'indigo-lighten-2'];

    async mounted() {
        notificationService.getNotifications().subscribe((notificaitons) => {
            console.log(notificaitons);
            this.notifications = notificaitons;
        });
    }

    unmounted() {
        this.notificationsSub?.unsubscribe();
        this.notificationsSub = null;
    }

    getNotificationColor() {
        return this.notifications.length > 0 ? 'warning' : 'primary';
    }
}

