import {ReplaySubject} from 'rxjs';
import _ from 'lodash';

class ArticlesService {
    articleSubject = new ReplaySubject<any>(1);
    articles = [
        {
          id: '1',
          title: "BGG's 10 Most Watched Games of June",
          headlineImage: '/assets/top_games2.jpg',
          tags: ['gaming', 'streaming'],
          markdownContent: `### 10. The Legend of Zelda: Majora's Mask
It's no suprise Majora's Mask makes the cut as it's hands down the greatest Zelda game ever made.  Yes, i'll fight you over that.  Zelda Tier List: 1) Majora's Mask, 2) Literally anything else, I don't care
    
&nbsp;
### 9. Xenogears

&nbsp;
### 8. Secret of Evermore
    
&nbsp;
### 7. Chrono Trigger
    
&nbsp;
### 6. Streets of Rage
    
&nbsp;
### 5. The Legend of Zelda: A Link to the Past
    
&nbsp;
### 4. The Legend of Zelda: The Wind Waker
    
&nbsp;
### 3. Final Fantasy II
    
&nbsp;
### 2. Super Mario RPG: Legend of the Seven Stars
    
&nbsp;
### 1. Final Fantasy VI
Toping our chart for viewership is Final Fantasy VI.  Arguably the greatest Final Fantasy of all time.`
        },
        {
          id: '2',
          title: "Zelda Tier List",
          headlineImage: '/assets/zelda.jpg',
          tags: ['gaming', 'controversial'],
          markdownContent: `
### 1. The Legend of Zelda: Majora's Mask
Hands Down the best zelda
    
&nbsp;
### 2. Literally any other zelda
It's like comparing ants to gods, it doesn't matter what comes next. `
        }
    ];

    constructor() {
        this.articleSubject.next(this.articles);
    }

    getArticles() {
        return this.articleSubject.asObservable();
    }

    async getArticle(id: string) {
        return _.find(this.articles, (item) => item.id === id);
    }
}


export const articlesService = new ArticlesService();