<template>
    <div></div>
</template>
<script>
import { Component, Vue } from 'vue-facing-decorator';
import {userService} from '../services/user.service';

@Component
export default class AuthView extends Vue {
    async mounted() {
        console.log('mounted');
        //get access token from location hash
        const hash = document.location.hash;

        const r = /access_token=([a-zA-Z0-9]*)&/.exec(hash);
        await userService.login(r[1]);
        let lastSite = null;
        try {
            lastSite = localStorage.getItem('authFailedPath');
            lastSite = JSON.parse(lastSite);
        } catch(e) {
            lastSite = null;
        }

        if (lastSite) {
            this.$router.push(lastSite);
        } else {
            this.$router.push({path: '/'});
        }
    }
}
</script>