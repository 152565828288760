import axios from 'axios';

const http = axios.create({
  baseURL: '',
});

class UploadFilesService {
  initialized = false;

  constructor() {
    this.initialized = true;
  }

  uploadAll(files: any, onUploadProgress: any, path='/api/sample/upload') {
    const formData = new FormData();
    files.forEach((file: File, index: number) => {
      formData.append('file' + index, file);
    });
    

    return http.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      timeout: 1000 * 60 * 60 * 24,
    });
  }

  upload(file: any, onUploadProgress: any, path='/api/sample/upload') {
    const formData = new FormData();

    formData.append('file', file);

    return http.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      timeout: 1000 * 60 * 60 * 24,
    });
  }

  getFiles() {
    return http.get('/files');
  }
}

export default new UploadFilesService();
