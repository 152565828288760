import {ReplaySubject} from 'rxjs';
import axios from 'axios';
import _ from 'lodash';
import { userService } from '../services/user.service';

interface channel {
    name: string;
    platform: string;
}

class ChannelsService {
    summaryData: Record<string, any> = {};
    channelData: any = null;
    summarySubject = new ReplaySubject<any>(1); 
    channels = [{name: 'backgroundgaming_', platform: 'twitch'}, {name: 'backgroundgaming_retro', platform: 'twitch'}, {name: 'backgroundgaming_rpg', platform: 'twitch'}, {name: 'zelda_tv247', platform: 'twitch'}, {name: 'backgroundgaming', platform: 'kick'}, {name: 'backgroundgaming', platform: 'youtube'}, {name: 'finalfantasy_tv247', platform: 'twitch'}, {name: 'pokemon_tv247', platform: 'twitch'}, {name: 'mario_tv247', platform: 'twitch'}, 
    {name: 'backgroundgaming_2', platform: 'twitch'}, {name: 'bggjukebox', platform: 'twitch'}];
    channelsSubject = new ReplaySubject<channel[]>(1);
    constructor() {
        this.channelsSubject.next(this.channels);
        this.summarySubject.next([]);
    }

    getChannels() {
        return this.channelsSubject.asObservable();
    }

    async getChannelInfo(channel: string, platform: string) {
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt}
        const meResp = await axios.get(`/api-authed/channels/${platform}/${channel}`, {headers: authHeaders});
        const viewers = meResp.data.viewers;
        const image = meResp.data.image;
        const streamOnline = meResp.data.online;
        const key = platform + '_' + channel;
        const channelData = {
            streamOnline,
            viewers, 
            image,
        };
        this.summaryData[key] = channelData;
        this.summarySubject.next(_.values(this.summaryData));
        return channelData;
    }

    getChannelSummary() {
        return this.summarySubject.asObservable();
    }


    async getChannelDetails() {
        if(this.channelData) {
            return this.channelData;
        }
        const resp = await axios.get('/api/bot/channelInfo');
        this.channelData = resp.data ?? [];
        return this.channelData;
    }

    async reloadPlaylist(channel: string) {
        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt};
        const resp = await axios.get(`/api/bot/reloadChannel/${channel}`, {headers: authHeaders});
        return resp.data;
    }
}

export const channelsService = new ChannelsService();