import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { AgGridVue } from "ag-grid-vue3";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import Markdown from 'vue3-markdown-it';
import 'highlight.js/styles/monokai.css';
import VueGtag from 'vue-gtag';

const customDarkTheme = {
    dark: true,
    colors: {
      background: "#15202b",
      surface: "#15202b",
      primary: "#3f51b5",
      secondary: "#03dac6",
      error: "#f44336",
      info: "#2196F3",
      success: "#4caf50",
      warning: "#fb8c00",
    },
   
  };

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: "customDarkTheme",
        themes: {
          customDarkTheme,
        },
    },
  })

createApp(App)
  .use(router)
  .use(vuetify)
  .use(Markdown)
  .component('v-grid', AgGridVue as any)
  .use(VueGtag, {config: {id: 'G-PQXNKLX0KM'}})
  .mount('#app')
