
import { Component, Vue } from 'vue-facing-decorator';
import {bountiesService} from '../services/bounties.service';
import _ from 'lodash';
import moment from 'moment';

@Component({
    components: {}
})
export default class BountySummary extends Vue {
    bountiesSub: any = null;
    bounties: any[] = [];
    colors = [ 'red-lighten-2', 'purple-lighten-2', 'green-lighten-1', 'indigo-lighten-2'];

    async mounted() {
       this.bountiesSub = bountiesService.getBounties().subscribe((bounties) => {
           this.bounties = _.filter(bounties, (bounty) => bounty.addedDate)
           this.bounties.sort((x) => x.addedDate);
            this.bounties = this.bounties.reverse().slice(0,5);
       });
    }

    unmounted() {
        this.bountiesSub?.unsubscribe();
        this.bountiesSub = null;
    }

    getFormattedDate(bounty: any) {
        return moment(bounty.addedDate).format('MM/DD/yyyy');
    }
}

