<template>
  <div class="fill-height fill-width" style="width: 100%;">
    <v-container fluid pa-0>
    <v-row align="center" justify="center" dense class="fill-height fill-width">
    <v-col cols="12" lg="6" md="6" class="grey lighten-2 fill-height d-flex flex-column justify-center align-center">
    <v-card
        class="mx-auto login-card"
        color="#2f3136"
        rounded="lg"
        width="400"
        style="min-height:500px"
        tile
      >
      <v-card-item>
      <v-card-title>Login With:</v-card-title>
      <v-divider></v-divider>
      <v-btn style="margin-top: 10px"  @click="twitchLogin" class="text-none" rounded="xl" elevation="8"><div class="twitch-icon"></div>Twitch</v-btn>
      </v-card-item>
    </v-card>
    </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.twitch-icon {
    background: url('../assets/twitch.png');
    background-size: auto;
    height: 30px;
    width: 30px;
    display: block;
    margin-right: 4px;
    /* Other styles here */
}

.login-card {
  margin: 30px;
  display: block;
}
</style>
<script>
export default {
  mounted: function() {
    console.log('login mounted');
  },
  methods: {
      twitchLogin() {
          //todo support variable 
          const urlBase = location.host === 'localhost' ? "http://localhost" : 'https://' + location.host;
          window.open(`https://id.twitch.tv/oauth2/authorize?client_id=n5jywrdi4nsh5ggxcv69te7a8wnb3j&redirect_uri=${urlBase}/auth&response_type=token&scope=user:read:email`, '_self');
      }
  },
}
</script>