<template>
    <div>
        <v-card style="margin: 5px; width: 350px;" rounded="lg" tile>
            <v-card-title>
                Streams
            </v-card-title>
            <v-card-text>
                <v-list-item
                  v-for="channel in channels" v-bind:key="channel.name"
                  :prepend-avatar="getChannelImage(channel.channel, channel.platform)">
                    <v-list-item-title><a :href="getChannelLink(channel.platform, channel.channel)">{{channel.channel}}</a></v-list-item-title>
                    <span class="label">{{channel?.currnetlyPlaying?.game || 'unknown'}} </span>
                </v-list-item>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { Component, Vue } from 'vue-facing-decorator';
import { botsService } from '../services/bots.service';
import AnimatedNumber from '../components/AnimatedNumber.vue';
@Component({
    components: {
        AnimatedNumber,
    }
})
export default class ChannelSummary extends Vue {
    channels = [];

    getChannelLink(platform, name) {
        if (platform === 'twitch') {
            return `https://twitch.tv/${name}`;
        } else if (platform === 'kick') {
            return `https://kick.com/${name}`;
        } if (platform === 'youtube') {
            return `https://www.youtube.com/watch?v=TcFOL04WOh8`;
        } else {
            return '';
        }
    }

    getChannelImage(channel, platform) {
        if (platform === 'twitch') {
            if(channel === 'backgroundgaming_') {
                return 'https://static-cdn.jtvnw.net/jtv_user_pictures/e6d7b133-9141-4063-a518-d16c50edc5f6-profile_image-300x300.jpeg';
            } else if(channel === 'backgroundgaming_retro') {
                return 'https://static-cdn.jtvnw.net/jtv_user_pictures/39a09984-fbd8-4e6a-a6e2-c48a99c4d29c-profile_image-300x300.png';
            } else {
                return 'https://static-cdn.jtvnw.net/jtv_user_pictures/2266c4bf-5046-482e-afba-637481e0a1e7-profile_image-300x300.png';
            }
        }  else if(platform === 'facebook') {
            return '/assets/facebook.png';
        } else if(platform === 'youtube') {
           return '/assets/youtube.png';
        } else if(platform === 'kick') {
            return '/assets/kick.png';
        } else {
            return '';
        }
    }
    
    async mounted() {
        this.botSub = botsService.getBots().subscribe((bots) => {
            this.channels = bots;
        });
    }

    unmounted() {
        this.botSub?.unsubscribe();
        this.botSub = null;
    }
}

</script>

<style scoped>
.label {
    opacity: .5;
    display: inline-block;
    width: 120px;
}
</style>