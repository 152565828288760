

import { Options, Vue } from 'vue-class-component';
import {articlesService} from '../services/articles.service';
import Markdown from 'vue3-markdown-it';


@Options({
  components: {Markdown},
})
export default class ArticleView extends Vue {
 article: any = null;
 id = '';
    created() {
        this.id = this.$route.params.id as string;
        this.setupArticle();
    }

   async beforeRouteUpdate(to: any) {
        this.id = to.params.id;
        this.setupArticle();
        //fetch article
   }

   async setupArticle() {
       this.article = await articlesService.getArticle(this.id);
   }
}

