import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue';
import AuthView from '../views/AuthView.vue';
import AdminView from '../views/AdminView.vue';
import ArticleView from '../views/ArticleView.vue';
import { userService } from '../services/user.service';
import GiveawayView from '../views/GiveawayView.vue';
import LogoutView from '../views/LogoutView.vue';
import BountyHunter from '../views/BountiesView.vue';
import BountyDetailView from '../views/BountyDetailView.vue';
import JukeboxView from '../views/JukeboxView.vue';
import GamesView from '../views/GamesView.vue';
import BracketView from '../views/BracketView.vue';
import BracketHistory from '../views/BracketHistoryView.vue';

async function authGuard(to: any): Promise<any> {
  await userService.ready;
  if (!userService.jwt) {
    localStorage.setItem('authFailedPath', JSON.stringify(to));
    return { name: 'login' };
  }
  return true;
}

async function adminGuard(to: any): Promise<any> {
  await userService.ready;
  if (!userService.admin) {
    localStorage.setItem('authFailedPath', JSON.stringify(to));
    return { name: 'login' };
  }
  return true;
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/article/:id',
    name: 'article',
    component: ArticleView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: LogoutView
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/giveaways',
    name: 'giveaways',
    component: GiveawayView,
    beforeEnter: authGuard,
  },
  {
    path: '/bounties',
    name: 'bounties',
    component: BountyHunter,
    beforeEnter: authGuard,
  },
  {
    path: '/bounties/:id',
    name: 'bountiesDetail',
    component: BountyDetailView,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    beforeEnter: adminGuard,
  },
  {
    path: '/jukebox',
    name: 'jukebox',
    component: JukeboxView,
  },
  {
    path: '/games',
    name: 'games',
    component: GamesView,
  },
  {
    path: '/bracket',
    name: 'bracket',
    component: BracketView,
  },
  {
    path: '/bracket-history',
    name: 'bracket-history',
    component: BracketHistory,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router


