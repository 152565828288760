import {ReplaySubject} from 'rxjs';
import axios from 'axios';

class YoutubeService {
    youtubeSubject = new ReplaySubject<any>(1);
    youtube: any = [];

    constructor() {
        this.youtubeSubject.next(this.youtube);
        this.fetchYoutube();
    }

    async fetchYoutube() {
        const resp = await axios.get('/api/youtube/');
        this.youtube = resp.data;
        console.log(resp.data);
        this.youtubeSubject.next(this.youtube);
    }


    getYoutube() {
        return this.youtubeSubject.asObservable();
    }
}


export const youtubeService = new YoutubeService();