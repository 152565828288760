import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ba68772"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.article)
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          style: {"padding":"15px","margin-left":"20px","margin-right":"20px","margin-top":"10px","margin-bottom":"20px"},
          class: "article"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                class: "centered",
                src: _ctx.article.headlineImage
              }, null, 8, _hoisted_2)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.article.title), 1)
            ]),
            _createVNode(_component_v_divider),
            _createVNode(_component_Markdown, {
              style: {"margin-top":"3px"},
              class: "faded",
              source: _ctx.article.markdownContent
            }, null, 8, ["source"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}