<template>
    <div style="width: 100%;">
       Logging Out
    </div>
</template>
<script>
import { Component, Vue } from 'vue-facing-decorator';
import { userService } from '../services/user.service';

@Component({
    components: {}
})
export default class extends Vue {
    async mounted() {
        await userService.logout();
        setTimeout(() => {
            this.$router.push({path: '/'});
        }, 2000);
    }
}

</script>

<style scoped>
.label {
    opacity: .5;
    display: inline-block;
    width: 120px;
}
</style>