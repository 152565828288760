<template>
    <div>
        <v-card style="margin: 5px; width: 350px;" rounded="lg" tile>
            <v-card-title>
                <v-list-item
                    :prepend-avatar="image"
                >
                <v-list-item-title><a :href="getChannelLink()" target="_blank">{{channel.name}}</a></v-list-item-title>
                </v-list-item>
            </v-card-title>
            <v-card-text>
                <v-divider style="margin-bottom: 5px;"></v-divider>
                <div><span class="label">platform </span><span>{{channel.platform || ''}}</span></div>
                
                <div><span class="label">stream online </span><span :style="{'color': streamOnline ? 'green' : 'red'}">{{streamOnline}}</span></div>
                <div><span class="label">bot online </span><span :style="{'color': botOnline ? 'green' : 'red'}">{{botOnline}}</span></div>
                <div><span class="label">viewers </span><span><AnimatedNumber :number="viewers"></AnimatedNumber></span></div>
                <div><span class="label">playing </span><span class="game">{{currentGame}}</span></div>
                <div><span class="label">videos </span><span>{{botVideos}}</span></div>
                <div><span class="label">video errors </span><a href="#" @click="toggleErrors()" style="color: red">{{ channelDetails?.errors?.length ?? '' }}</a></div>
                <div v-if="showErrors">
                    <div v-for="error, $index in channelDetails.errors" v-bind:key="$index">{{ error }}</div>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    variant="tonal" 
                    elevation="4"
                    :dsiabled="!enableReloadButton"
                    @click="reloadPlaylist()"
                >
                    Reload Playlist
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { channelsService } from '../services/channels.service';
import { botsService } from '../services/bots.service';
import AnimatedNumber from '../components/AnimatedNumber.vue';
import _ from 'lodash';

@Component({
    components: {
        AnimatedNumber,
    }
})
export default class Channel extends Vue {
    @Prop() channel = {};
    image = '';
    viewers = 0;
    streamOnline = false;
    botOnline = false;
    botVideos = 0;
    botSub = null;
    channelInterval = null;
    currentGame  = '';
    channelDetails = {};
    showErrors = false;

    toggleErrors() {
        this.showErrors = !this.showErrors;
    }

    getChannelLink() {
        if (this.channel?.platform === 'twitch') {
            return `https://twitch.tv/${this.channel.name}`;
        } else if (this.channel?.platform === 'kick') {
            return `https://kick.com/${this.channel.name}`;
        } if (this.channel?.platform === 'youtube') {
            return `https://www.youtube.com/watch?v=TcFOL04WOh8`;
        } else {
            return '';
        }
    }
    async fetchTwitch() {
        const info = await channelsService.getChannelInfo(this.channel.name, this.channel.platform);
        this.viewers = info.viewers;
        this.image = info.image;
        this.streamOnline = info.streamOnline; 
    }

    async reloadPlaylist() {
        this.enableReloadButton = false;
        await channelsService.reloadPlaylist(this.channel?.name);
        setTimeout(() => this.enableReloadButton = true, 1000);
    }
    
    async mounted() {
        if (this.channel?.platform === 'twitch' || this.channel?.platform === 'kick' ) {
            await this.fetchTwitch();
            this.channelInterval = setInterval(() => {
                this.fetchTwitch();
            }, 10 * 1100);
        } else if(this.channel?.platform === 'facebook') {
            this.streamOnline = false; 
            this.image = '/assets/facebook.png';
        } else if(this.channel?.platform === 'youtube') {
            this.streamOnline = false;
            this.viewers = 0;
            this.image = '/assets/youtube.png';
        }
        this.botSub = botsService.getBots().subscribe((bots) => {
//             {channel: "backgroundgaming_rpg", platform: "twitch", videos: 659,…}
// channel
// : 
// "backgroundgaming_rpg"
// currnetlyPlaying
// : 
// {path: "G:\VideosEarthBound Pt.01.mp4", next: "G:\Videos\EarthBound Pt.02.mp4", part: 0,…}
// lastOnline\
// : 
// 1689183640956
// platform
// : 
// "twitch"
// videoErrors
// : 
// ["unable to find video at path: G:\Videos\Pokémon Blue Pt.01.mp4",…]
// videos
// : 
// 659
            const bot = bots[this.channel.platform + '_' + this.channel.name] || {}; 
            if (bot?.lastOnline && bot.lastOnline + 1000 * 60 * 2 > Date.now()) { // 2 minute buffer
                this.botOnline = true;
            } else {
                this.botOnline = false;
            }
            this.botVideos = bot?.videos;
            this.currentGame = bot?.currnetlyPlaying?.game || ''; 
        });

        let channels = await channelsService.getChannelDetails();

        this.channelDetails = _.find(channels, (ch) => ch.name === this.channel.name);
    }

    unmounted() {
        this.botSub?.unsubscribe();
        this.botSub = null;
        if (this.channelInterval) {
            clearInterval(this.channelInterval);
            this.channelInterval = null;
        }
    }
}

</script>

<style scoped>
.label {
    opacity: .5;
    display: inline-block;
    width: 120px;
}

.game {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    display: inline-block;
}
</style>