
import { Component, Vue } from 'vue-facing-decorator';
import { mergeProps } from 'vue';
import {bountiesService} from '../services/bounties.service';
import _ from 'lodash';
import {userService} from '../services/user.service';

@Component({
    components: {}
})
export default class extends Vue {
    currentRank = 1;
    bounties: any[] = [];
    reserved: any[] = [];
    search = '';
    tab: any = {};
    closeInfo = false;
    mergeProps = mergeProps;
    menu: Record<string, boolean> = {};
    ranks = [
        'Initiate',
        'Greenhorn',
        'Bounty Hunter',
        'Elite Bounty Hunter',
        'Master Bounty Hunter',
        'Grandmaster Bounty Hunter',
    ];

    //these will come from the server
    tasks = [
        {
            name: "Review the bounty policy",
            link: 'https://tinyurl.com/BGG-Bounty-Program',
            completed: true,
            completedAt: Date.now(),
        },
        {
            name: 'Record a trial bounty',
            completed: false,
        }
    ];

    perks = {
        'Initiate':  ['First step to recording bounties'],
        'Greenhorn':  ['Can reserve 1 bounty', "unlocks up to tier 3 bounties"],
        'Bounty Hunter':  ['Can reserve 2 bounties', '2 extra weeks lead time', 'unlocks up to tier 5 bounties'],
        'Elite Bounty Hunter':  ['Can reserve 3 bounties', '4 extra weeks lead time'],
        'Master Bounty Hunter':  ['Can reserve 3 bounties'],
        'Grandmaster Bounty Hunter':  ['Can reserve 5 bounties'],
    };

    requirements = {
        'Initiate':  ['Create a backgroundgaming.com account by logging in with twitch'],
        'Greenhorn':  ['Join Discord', 'Read Instructions'],
        'Bounty Hunter':  ['Complete 3 bounties', 'Collect $50 of bounties'],
        'Elite Bounty Hunter':  ['Complete 10 bounties', 'Collect $250 of bounties'],
        'Master Bounty Hunter':  ['Complete 25 bounties', 'Collect $500 of bounties'],
        'Grandmaster Bounty Hunter':  ['Complete 50 bounties', 'Collect $1000 of bounties'],
    };
    completedBounties = [{name: 'loading'}]
    selected: Record<string, boolean> = {};
    selectedTab: Record<string, boolean> = {};
    bountyRunDescription = '';
    availableBountiesSub: any = null;
    activeBountiesSub: any = null;
    bountyLevel = 0;
    userSub2: any = null;
    loaded = false;
    loggedIn = true;
    bountyProgramError = '';
    
    async mounted() {
        this.userSub2 = userService.getCurrentUser().subscribe(async () => {
            this.loggedIn = userService.isLoggedIn();
            this.setupUser();
        });
        this.availableBountiesSub = bountiesService.getBounties().subscribe((bounties: any[]) => {
            this.bounties = bounties;
        });

        this.completedBounties = await bountiesService.getClaimedBounties();

        this.activeBountiesSub = bountiesService.getActiveBounties().subscribe((bounties) => {
            this.reserved = bounties;
        });

        try {
            await this.setupUser();
        } catch(e) {
            this.loaded = true;
        }

    }

    async promoteBountyLevel() {
        await userService.promoteBountyLevel();
        this.setupUser();
    }

    async completeTask(task: any) {
        const data = await userService.completeTask(task);
        this.tasks = data.data.tasks;
    }

    isBountyMember() {
        return this.bountyLevel > 0;
    }

    canPromote() {
        let canPromote = true;
        _.each(this.tasks, (task) => {
            if(!task.completed) {
                canPromote = false;
                return false;
            }
        });
        return canPromote;
    }

    async joinBountyProgram() {
        const data = await userService.joinProgram();
        if(data.success) {
            await this.setupUser();
            await bountiesService.fetchBounties();
            this.bountyLevel = 1;
        } else {
            this.bountyProgramError = data.reason;
        }
 
    }

    async setupUser() {
        const data = await userService.getUserData();
        this.bountyLevel = data.bounty_level;
        this.tasks = data?.data?.tasks || [];
        this.loaded = true;
    }

    loadBounty(bounty: any) {
        this.$router.push({name: 'bountiesDetail', params: {id: bounty.name}});
    }
    
    unmounted() {
        this.availableBountiesSub?.unsubscribe();
        this.userSub2?.unsubscribe();
        this.userSub2 = null;
        this.availableBountiesSub = null;
    }

    async reserveBounty(bounty: any) {
        if(!this.bountyRunDescription) {
            alert('please enter a description');
            return;
        }
        await bountiesService.reserve(bounty, this.bountyRunDescription);
        this.bountyRunDescription = '';
        //we should do this through the service and just get the update via observable.  
        // this.bounties =  _.filter(this.bounties, (b) => b.name !== bounty.name);
        // this.reserved.push(bounty);
    }
    
    cancelBounty($event: Event, bounty: any) {
        this.reserved = _.filter(this.reserved, (b) => b.name !== bounty.name);
        this.bounties.push(bounty);
        bountiesService.cancel(bounty);
        $event.preventDefault();
        $event.stopImmediatePropagation();
        return false;
    }

    filterBounties() {
        if(!this.search) {
            return this.bounties;
        }
        const re = new RegExp(this.search.toLowerCase(), 'i')
        return _.filter(this.bounties, (bounty) => {         
            return re.test(bounty.name);
        });
    }

    getDotColor(i: number) {
        if (i + 1 < this.bountyLevel) {
            return 'green';
        } else if(i + 1 == this.bountyLevel) {
            return 'amber';
        } 
        return 'blue-grey-lighten-3';
    }
}

