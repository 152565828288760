import { socketService } from '../services/socket.service';
import { ReplaySubject } from 'rxjs';
import axios from 'axios';
import _ from 'lodash';
import { userService } from './user.service';

class BracketService {
    bracket = { rounds: [] };
    votes = {};
    bracketSub = new ReplaySubject<any>(1);
    votesSub = new ReplaySubject<any>(1);
    tempVotesSub = new ReplaySubject<any>(1);
    tempVotes: any[] = [];

    constructor() {
        this.bracketSub.next(this.bracket);
        this.setupData();
        this.votesSub.next([]);
    }

    async getAllBrackets() {
        const resp = await axios.get(`/api/bracket`);
        return resp.data;
    }

    async setupData() {
        let resp = await axios.get(`/api/bracket/current`);
        this.bracket = resp.data;
        this.bracketSub.next(this.bracket);
        await userService.ready;
        if (userService.jwt) {
            const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt };
            resp = await axios.get(`/api/bracket/current/votes`, { headers: authHeaders });
            this.votes = resp.data;
            this.votesSub.next(this.votes);
        }



        socketService.on('bracket:update', (data: any) => {
            //update the bot and send event
            this.bracket = data;
            this.bracketSub.next(this.bracket);
        });

        socketService.on('votes:update', (data: any) => {
            this.votes = data;
            this.votesSub.next(this.votes);
        });
    }

    getBracket() {
        return this.bracketSub.asObservable();
    }

    getVotes() {
        return this.votesSub.asObservable();
    }

    getTempVotes() {
        return this.tempVotesSub.asObservable();
    }

    async vote(v: any) {
        if (!userService.jwt) {
            const pid = v.playerId;
            const withoutIndex = pid % 2 === 0 ? pid - 1 : pid + 1;
            this.tempVotes = _.sortBy(_.uniq([pid, ..._.filter(this.tempVotes, (tv) => tv !== withoutIndex)])); //remove the opposite one
            this.tempVotesSub.next(this.tempVotes);
            return;
        }
        console.log('voting');

        const authHeaders = { 'Authorization': 'Bearer ' + userService.jwt };

        //do the auth request in the future
        await axios.post('/api/bracket/current/vote', v, { headers: authHeaders });
    }
}




export const bracketService = new BracketService();