import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e65c330"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"position":"absolute","z-index":"5000","font-size":"8px"}
}
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, { "offset-y": "" }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps({
        dark: "",
        icon: ""
      }, props), {
        default: _withCtx(() => [
          (_ctx.notifications.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.notifications.length), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_v_icon, {
            color: _ctx.getNotificationColor(),
            icon: "mdi-bell"
          }, null, 8, ["color"])
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        color: "blue-grey-darken-3",
        raised: "",
        dark: "",
        cover: "",
        "min-width": "300",
        style: {"border":"1px solid silver"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(" Notifications ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_list, { dense: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: `notification-key-${notification.bounty}`,
                  color: "blue-grey-darken-3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          href: '/bounties/' + notification.bounty
                        }, "Approve " + _toDisplayString(notification.bounty), 9, _hoisted_2)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}