
import { Component, Vue } from 'vue-facing-decorator';
import {giveawayManager} from '../services/giveaway.service';

@Component({
    components: {}
})
export default class extends Vue {
    giveaways: any[] = [];
    async mounted() {
       this.giveaways = await giveawayManager.getGiveaways();
    }
}

