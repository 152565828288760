<template>
  <div style="overflow: hidden" class="fill-height fill-wdith">
    <v-toolbar
      dark
      prominent
      class="main-toolbar"
      >
        <v-app-bar-nav-icon  @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Background Gaming</v-toolbar-title>
        <v-spacer></v-spacer>
      <!-- <login></login> -->
        <v-toolbar-items class="hidden-sm-and-down">
          <!-- <v-btn flat>Link One</v-btn>
          <v-btn flat>Link Two</v-btn> -->
          <Notification></Notification>
        </v-toolbar-items>
      </v-toolbar>
      <v-layout class="main-content fill-width">
       <v-navigation-drawer 
         v-model="drawer"  
         location="left"
         temporary
         style="padding: 10px;">
        <v-list-item
          v-if="user"
          :prepend-avatar="avatar"
          :title="user"
        ></v-list-item>
         <v-divider></v-divider>
          <v-list>
            <v-list-item v-for="item in navItems" v-bind:key="item.name"><router-link :to="'/' + item.route">{{item.name}}</router-link>
            </v-list-item>
            <v-list-item v-if="user"><router-link :to="'/logout'">logout</router-link>
            </v-list-item>
          </v-list>
      </v-navigation-drawer>
      <router-view/>
    </v-layout>
  </div>
</template>
<script>
import {userService} from './services/user.service';
import Notification from './components/Notification.vue';
export default {
  mounted: function() {
    this.userSub2 = userService.getCurrentUser().subscribe((user) => {
      this.user = user?.username;
      this.avatar = user?.profileImageLink;
    });
    this.userSub = userService.getUserRoutes().subscribe((routes) => {
      this.navItems = routes;
    });
    console.log('mounted');
  },
  unmounted() {
    if(this.userSub) {
      this.userSub.unsubscribe();
      this.userSub = null;
    }
     if(this.userSub2) {
      this.userSub2.unsubscribe();
      this.userSub2 = null;
    }
  },
  components: {
    Notification,
  },
  data() {
    return { 
      notifications: [],
      drawer: false,
      user: '',
      avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/zealenth-profile_image-f705bb2ecd1e7bec-70x70.png',
      navItems: [
        'foo',
        'bar',
        'baz'
      ]
    }
  } 
    
}
</script>

<style>
html { overflow: hidden !important }

html, body, #app, #app>div {
  height: 100%
}

.main-toolbar {
  background: rgba(26, 47, 88, 1) !important;
  background: linear-gradient(90deg, rgba(26, 47, 88, 1) 0%, rgba(18, 35, 60, 1) 20%,   rgba(15, 25, 45, 1) 50%,  rgba(18, 35, 60, 1) 80%, rgba(26, 47, 88, 1) 100%) !important;
  border-bottom: 1px solid #353535;
}

.main-content {
  height: calc(100% - 64px);
}
</style>