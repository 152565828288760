

/*

{"id":"2824","name":"Dragon Quest VIII: Journey of the Cursed King","description":"",
"platforms":["Mobile","Nintendo 3DS","PlayStation 2"],
"imageUrl":"https://howlongtobeat.com/games/Dragon_Quest_VIII_Journey_of_the_Cursed_King.jpeg",
"timeLabels":[["Main","Main"],["Main + Extra","Main + Extra"],["Completionist","Completionist"]],
"gameplayMain":62,"gameplayMainExtra":80,"gameplayCompletionist":120,"similarity":0.29,"
searchTerm":"Dragon Quest 8","playableOn":["Mobile","Nintendo 3DS","PlayStation 2"]}

*/

import { Options, Vue } from 'vue-class-component';
import {bountiesService} from '../services/bounties.service';
import {userService} from '../services/user.service';
import {howLongService} from  '../services/howlong.service';
import UploadService from '../services/file-upload.service';
import moment from 'moment';
import { mergeProps } from 'vue';

interface Action {
    action?: string;
    bounty?: string;
    completed?: boolean;
    completeddata?: any;
    createdtime?: number;
    id: number;
    type?: string;
}
@Options({
  components: {},
})
export default class BountyDetailView extends Vue {
 bounty: any = null;
 actions: Action[] = [];
 userSub: any = null;
 isAdmin = false;
 details: any = {};
 user = '';
 actionInProgress = false;
 id = '';
 fileUploads: any[] = [];
 selectedFiles: any = [];
 currentFile: any = undefined;
 mergeProps  = mergeProps;
 message = '';
 progress = 0;
 uploadError = '';
 foo = 'https://www.w3schools.com/tags/movie.mp4';
    created() {
        this.id = this.$route.params.id as string;
        this.setupBounty();
        this.buildTimeline();
        console.log('bounty detail view');
    }

   async beforeRouteUpdate(to: any) {
        this.id = to.params.id;
        this.setupBounty();
        this.buildTimeline();
        //fetch article
   }

   mounted() {
       this.userSub = userService.getCurrentUser().subscribe((user) => {
           this.user = user?.username || '';
           this.isAdmin = userService.admin;
       });
   }

    selectFile() {
      this.selectedFiles = (this.$refs as any)?.file[0]?.files;
    }

    
    async submit(action: any, isActive: any) {
        //TODO: handle exceptions (download failures, etc)
        if (!this.selectedFiles?.item(0)) {
            //todo fix to modal
            alert('at least one file must be selected');
            return;
        } else if( /mp4$/i.test(this.selectedFiles?.item(0))) {
            alert('file must be a .mp4 file');
        }
        const data = await this.upload();
        /** {
            "message": "Success",
            "data": {
                "$metadata": {
                    "httpStatusCode": 200,
                    "requestId": "W40G6YAE6TNJ2M46",
                    "extendedRequestId": "sZUJ8Lt/IVkx7Hp6re3zCHJTbXx6A/S0AMPHKJ6zZ4rPVnqGa/QNxePkSH6B5tc2FnJjiuMKJMk=",
                    "attempts": 1,
                    "totalRetryDelay": 0
                },
                "ETag": "\"d9061d3da8601932e98f79ec8ba1c877\"",
                "ServerSideEncryption": "AES256",
                "Bucket": "bggvids",
                "Key": "1691605664572-file_example_MP4_480_1_5MG.mp4",
                "Location": "https://bggvids.s3.us-east-2.amazonaws.com/1691605664572-file_example_MP4_480_1_5MG.mp4"
            }
        }
        */
        if (action.type === 'pending_sample') {
            await bountiesService.submitSample(action, data);
        } else {
            await bountiesService.submitVideo(action, data);
        }

        //we should really make these socket events and subscribe to bounties...  optimizations for later
        await this.setupBounty();
        await this.buildTimeline();
        isActive.value = false;
    }

    async upload() {
      this.currentFile = this.selectedFiles.item(0);
    
      return await UploadService.uploadAll([...this.selectedFiles], (event: any) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then((response) => {
          this.message = 'file uploaded successfully';
          console.log(this.currentFile);
          this.selectedFiles = undefined;
          return response;
        })
        .catch(() => {
          this.progress = 0;
          this.uploadError = 'Could not upload the file!';
          this.selectedFiles = undefined;
          this.currentFile = undefined;
        });
    }

   unmounted() {
       this.userSub?.unsubscribe();
   }

   async approve(action: Action) {
       if( action.type === 'start_approval') {
        await bountiesService.approve(action);
       } else if( action.type === 'pending_sample_approval') {
        await bountiesService.approveSample(action);
       } else if( action.type === 'pending_submit_approval') {
        await bountiesService.approveVideo(action);
       } 
       await this.buildTimeline();

   }

   async reject(action: Action) {
        console.log('todo: implement reject');
        if( action.type === 'start_approval') {
            await bountiesService.reject(action);
        } else if( action.type === 'pending_sample_approval') {
            await bountiesService.rejectSample(action);
        }
        await this.buildTimeline();
   }

   isPendingPayment(action: Action) {
    return action.type === 'pending_payout';    
   }

   async markPaid(action: Action) {
       await bountiesService.markPaid(action);
       await this.buildTimeline();
   }

   async abandon() {
        await bountiesService.cancel(this.bounty);
        await this.setupBounty();
        await this.buildTimeline();
   }

   async setupBounty() {
       this.actionInProgress = true;
       this.bounty = await bountiesService.getBounty(this.id);
       this.details = await howLongService.getGameDetails(this.id);
       this.actionInProgress = false;
   }

   getActionTime(action: Action) {
       if(!action || !action.createdtime) {
           return '';
       }
       let d = 0;
       try {
           d = parseInt(action.createdtime as any);
       } catch(e) {
           console.error(e);
       }
       return moment(new Date(d)).format('MMMM Do YYYY')
   }

   getActionType(action: Action) {
        switch(action.type) {
            case 'start_approval': 
                return action.completed ? (action?.completeddata?.status === 'approved' ? 'Approved' : 'Rejected') : 'Pending Approval';
            case 'started': 
                return 'Reservation Started';
            default:
                return '';
       }
   }

   isApproval(action: Action) {
       return action.type === 'start_approval' || action.type === 'pending_submit_approval' ||  action.type === 'pending_sample_approval';
   }

   isPendingSubmit(action: Action) {
       return action.type === 'pending_sample' || action.type === 'pending_video';
   }

   async buildTimeline() {
       this.actions = [...(await bountiesService.getGameActions(this.id)).map((d: any) => d)].reverse();
       console.log( this.actions[0] );
       console.log(this.actions[0]?.createdtime)
   }
}

