import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09bcfaac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_v_timeline = _resolveComponent("v-timeline")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, {
      style: {"margin":"5px","width":"350px"},
      rounded: "lg",
      tile: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createTextVNode(" Latest Bounties ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_timeline, {
              density: "compact",
              align: "start"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bounties, (bounty, index) => {
                  return (_openBlock(), _createBlock(_component_v_timeline_item, {
                    key: bounty.name,
                    "dot-color": _ctx.colors[index%4],
                    size: "xx-small"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getFormattedDate(bounty)), 1),
                        _createElementVNode("div", null, _toDisplayString(bounty?.name || 'unknown'), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["dot-color"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}